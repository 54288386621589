// import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';

import java from '../assets/img/java.png';
import sb from '../assets/img/spring.png';
import javs from '../assets/img/js.png';
import types from '../assets/img/ts.png';
import html from '../assets/img/html.png';
import css from '../assets/img/css.png';
import ang from '../assets/img/angular.png';
import re from '../assets/img/react.png';
import njs from '../assets/img/nodejs.png';
import db from '../assets/img/database.png';
import jen from '../assets/img/jen.png';
import dck from '../assets/img/docker.png';
import kub from '../assets/img/kub.png';
import as from '../assets/img/as.png';
import ij from '../assets/img/ij.svg';
import ws from '../assets/img/ws.svg';
import vsc from '../assets/img/vscode.png';
import kib from '../assets/img/kib.svg';
import graf from '../assets/img/graf.png';
import gh from '../assets/img/ghl.png';

export const Skills = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const items = [
        {
            imgSrc: java,
            altText: "Java",
            links: [
                { url: "https://www.w3schools.com/java/", text: "Java" }
            ]
        },
        {
            imgSrc: sb,
            altText: "Spring Boot",
            links: [
                { url: "https://spring.io/projects/spring-boot", text: "Spring Boot" }
            ]
        },
        {
            imgSrc: javs,
            altText: "JavaScript",
            links: [
                { url: "https://www.w3schools.com/js/", text: "JavaScript" }
            ]
        },
        {
            imgSrc: types,
            altText: "TypeScript",
            links: [
                { url: "https://www.typescriptlang.org/", text: "TypeScript" }
            ]
        },
        {
            imgSrc: html,
            altText: "HTML",
            links: [
                { url: "https://www.w3schools.com/html/", text: "HTML" }
            ]
        },
        {
            imgSrc: css,
            altText: "CSS",
            links: [
                { url: "https://www.w3schools.com/css/", text: "CSS" }
            ]
        },
        {
            imgSrc: ang,
            altText: "Angular",
            links: [
                { url: "https://angular.dev/", text: "Angular" }
            ]
        },
        {
            imgSrc: re,
            altText: "React",
            links: [
                { url: "https://react.dev/", text: "React" },
                { url: "https://reactnative.dev/", text: "React Native" }
            ]
        },
        {
            imgSrc: njs,
            altText: "Nodejs",
            links: [
                { url: "https://nodejs.org/en", text: "Node.js" }
            ]
        },
        {
            imgSrc: db,
            altText: "DB",
            links: [
                { url: "https://www.oracle.com/database/sqldeveloper/", text: "Oracle" },
                { url: "https://www.mysql.com/", text: "MySQL" },
                { url: "https://firebase.google.com/docs", text: "Firebase" }
            ]
        },
        {
            imgSrc: gh,
            altText: "Git",
            links: [
                { url: "https://github.com/", text: "GitHub" },
                { url: "https://about.gitlab.com/", text: "GitLab" }
            ]
        },
        {
            imgSrc: jen,
            altText: "Jenkins",
            links: [
                { url: "https://www.jenkins.io/", text: "Jenkins" }
            ]
        },
        {
            imgSrc: dck,
            altText: "Docker",
            links: [
                { url: "https://www.docker.com/", text: "Docker" }
            ]
        },
        {
            imgSrc: kub,
            altText: "Kubernetes",
            links: [
                { url: "https://kubernetes.io/", text: "Kubernetes" }
            ]
        },
        {
            imgSrc: as,
            altText: "Android Studio",
            links: [
                { url: "https://developer.android.com/studio", text: "Android Studio" }
            ]
        },
        {
            imgSrc: ij,
            altText: "IntelliJ",
            links: [
                { url: "https://www.jetbrains.com/idea/", text: "IntelliJ" }
            ]
        },
        {
            imgSrc: ws,
            altText: "WebStorm",
            links: [
                { url: "https://www.jetbrains.com/webstorm/", text: "WebStorm" }
            ]
        },
        {
            imgSrc: vsc,
            altText: "VS Code",
            links: [
                { url: "https://code.visualstudio.com/", text: "VS Code" }
            ]
        },
        {
            imgSrc: kib,
            altText: "Kibana",
            links: [
                { url: "https://www.elastic.co/kibana", text: "Kibana" }
            ]
        },
        {
            imgSrc: graf,
            altText: "Grafana",
            links: [
                { url: "https://grafana.com/", text: "Grafana" }
            ]
        },
    ];

    const CarouselItem = ({ imgSrc, altText, links }) => (
        <div className="item">
            <img src={imgSrc} alt={altText} />
            <div className="overlay">
                {links.map((link, index) => (
                    <a key={index} href={link.url} target="_blank" rel="noopener noreferrer" className={`txt ${index > 0 ? 'second' : ''}`}>
                        {link.text}
                    </a>
                ))}
            </div>
        </div>
    );

    return (
        <section className="skill" id="skills">
            <Container fluid={true}>
                <Row>
                    <Col size={12}>
                        <h2>Skills</h2>
                        <br />
                        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlay
                                autoPlaySpeed={2500}
                                centerMode={false}
                                className="owl-carousel owl-theme skill-slider"
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable
                                focusOnSelect={false}
                                infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={responsive}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={2}
                                swipeable
                            >
                                {items.map((item, index) => (
                                    <CarouselItem key={index} {...item} />
                                ))}
                            </Carousel>
                        </AnimationOnScroll>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}